import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PageLoader from '../../components/shared/page-loader/PageLoader';
import { useLocation, useParams } from 'react-router-dom';
import TemplateModuleService from '../../services/TemplateModuleService';
import { ToastType, useToasts } from '../../contexts/ToastContext';
import SectionsEditor from '../../components/module-builder/SectionsEditor';
import { ModuleTemplate } from '../../models/Module';
import ModuleFormsEditor from '../../components/module-builder/ModuleFormsEditor';
import ClientTemplateModuleService from '../../services/ClientTemplateModuleService';
import RouteGuard from '../../components/shared/RouteGuard';
import { ApiResponse } from '../../models/ApiResponse';
import { useTranslation } from 'react-i18next';
import { AdminLayout } from './Layout';
import { useCurrentClient } from '../../global-state/Clients';
import { useTopNavHeading } from '../../global-state/Workspace';

const ModuleBuilder: FC = () => {
  const currentClient = useCurrentClient((x) => x.value);
  const [module, setModule] = useState<ModuleTemplate | null>(null);
  const [loading, setLoading] = useState(false);
  const [changesPending, setChangesPending] = useState(false);
  const [afterSaveCallback, setAfterSaveCallback] = useState<(() => Promise<unknown>)[]>([]);
  const { t } = useTranslation('module-builder');

  const { templateModuleId } = useParams<{ templateModuleId: string }>();

  const location = useLocation();
  const mode = useMemo(() => (location.pathname.includes('/sections') ? 'sections' : 'forms'), [location.pathname]);

  const toasts = useToasts();
  const toastsRef = useRef(toasts);
  const setTopNavheading = useTopNavHeading((x) => x.setValue);

  const templateService = useMemo(() => {
    if (currentClient) {
      return new ClientTemplateModuleService(currentClient.id);
    }
    return TemplateModuleService;
  }, [currentClient]);

  useEffect(() => {
    setTopNavheading(t('heading'));
    return () => {
      setTopNavheading('');
    };
  }, [setTopNavheading, t]);

  useEffect(() => {
    const failsafe = localStorage.getItem(`module-builder.failsafe.module.${templateModuleId}`);
    if (!failsafe) {
      templateService.getTemplate(templateModuleId as string).then((res) => {
        setModule(res.data);
        setLoading(false);
      });
      return;
    }

    setModule(JSON.parse(failsafe));
    setChangesPending(true);

    toastsRef.current.addToast({
      title: t('toasters.module-restored.title'),
      description: t('toasters.module-restored.description'),
      type: ToastType.INFO,
      expiresInMs: 7000,
    });
  }, [t, templateModuleId, templateService]);

  const addAfterSaveCallback = (callback: () => Promise<unknown>) => {
    setAfterSaveCallback((prev) => [...prev, callback]);
  };

  const patchModule = (value: Partial<ModuleTemplate>) => {
    setChangesPending(true);
    const newValue = Object.assign({}, module, value);

    setModule(newValue);
    localStorage.setItem(`module-builder.failsafe.module.${templateModuleId}`, JSON.stringify(newValue));
  };

  const saveTemplate = () => {
    if (!module) {
      return Promise.resolve(false);
    }

    const save = changesPending
      ? templateService.updateTemplate(templateModuleId as string, module)
      : // eslint-disable-next-line @typescript-eslint/no-explicit-any
        Promise.resolve<ApiResponse<ModuleTemplate>>({} as any);
    return save
      .then(async (res) => {
        await Promise.all(afterSaveCallback.map((todo) => todo()));
        setAfterSaveCallback([]);

        return res;
      })
      .then((res) => {
        if (changesPending) {
          setModule(res.data as ModuleTemplate);
        }
        setChangesPending(false);

        localStorage.removeItem(`module-builder.failsafe.module.${templateModuleId}`);

        toasts.addToast({
          title: t('toasters.module-updated.title'),
          type: ToastType.SUCCESS,
          expiresInMs: 5000,
        });
        return true;
      })
      .catch((e) => {
        toasts.addToast({
          title: t('toasters.module-update-failed.title'),
          description: e?.meta?.message,
          type: ToastType.ERROR,
        });
        return false;
      });
  };

  const deleteFailsafe = async () => {
    localStorage.removeItem(`module-builder.failsafe.module.${templateModuleId}`);
    setChangesPending(false);
    //set Module to initial state
    templateService.getTemplate(templateModuleId as string).then((res) => {
      setModule(res.data);
      setLoading(false);
    });
  };

  const refetch = useCallback(() => {
    setLoading(true);
    templateService.getTemplate(templateModuleId as string).then((res) => {
      setModule(res.data);
      setLoading(false);
    });
  }, [templateModuleId, templateService]);

  return (
    <AdminLayout>
      <PageLoader loading={loading}>
        {module && (
          <div className="h-full" data-cy="module-builder">
            {mode === 'sections' && (
              <SectionsEditor
                afterSaveCallback={addAfterSaveCallback}
                changesPending={changesPending}
                saveTemplate={saveTemplate}
                module={module}
                patchModule={patchModule}
                refetch={refetch}
              />
            )}
            {mode === 'forms' && (
              <ModuleFormsEditor
                afterSaveCallback={addAfterSaveCallback}
                changesPending={changesPending}
                saveTemplate={saveTemplate}
                module={module}
                patchModule={patchModule}
                refetch={refetch}
              />
            )}
          </div>
        )}

        <RouteGuard when={changesPending} onSave={saveTemplate} onContinue={deleteFailsafe} />
      </PageLoader>
    </AdminLayout>
  );
};

export default ModuleBuilder;
